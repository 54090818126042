import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class PreviewService {
    public preview     : any = {};
    private previewS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.preview);
    previewS$          : Observable<any> = this.previewS.asObservable();

    constructor() {
      if (typeof localStorage != 'undefined') {
         this.preview = JSON.parse(localStorage.getItem('preview_Data'));
        if(this.preview == null)
          this.preview = {};

        this.previewS.next(this.preview);
      }
    }

    getpreview() {
      return this.preview;
    }

    getpreview_array() {
      let temp_arr = [];
      for (let key in this.preview) {
          temp_arr.push(this.preview[key]);
      }
      return temp_arr;
    }

    getpreview_Count() {
      var keys = Object.keys(this.preview);
      return keys.length;
    }

    addPreviewData(previewData: any) {
      // console.log("this.previewdata => : ",previewData);
      this.preview = previewData;
      this.previewS.next(this.preview);
      
      localStorage.setItem('preview_Data', JSON.stringify(this.preview));
    }

    removefavoriteProp(index: any) {
      delete this.preview[index];
      // this.preview.splice(index, 1);
      this.previewS.next(this.preview);

      console.log("this.preview: ",this.preview);

      localStorage.setItem('favoriteProps', JSON.stringify(this.preview));
    }
}
