import { Component, AfterViewInit, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/_services';

import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit {

  public pageInfo : any;
  // this is for the open close
  public isActive     = true;
  public showMenu     = '';
  public showSubMenu  = '';
  public propDropdown = false;

  // For logged Admin
  public admin        = { isAdmin: false};

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
      this.router.events
        .filter(event => event instanceof NavigationEnd)
        .map(() => this.activatedRoute)
        .map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
        .filter(route => route.outlet === 'primary')
        .mergeMap(route => route.data)
        .subscribe(event => {
          this.pageInfo = event;

          this.showMenu = this.pageInfo.url;

          if(this.showMenu === 'approved-properties' || this.showMenu === 'pending-properties')
            this.propDropdown = true;
          else
            this.propDropdown = false;

        });


        // Get Logged Admin
        this.authenticationService.currentUserS$.subscribe(admin => {
            this.admin = admin;
        });
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  // End open close
  ngAfterViewInit() {
    $(function() {
      $('.sidebartoggler').on('click', function() {
        if ($('body').hasClass('mini-sidebar')) {
          $('body').trigger('resize');
          $('.scroll-sidebar, .slimScrollDiv')
            .css('overflow', 'hidden')
            .parent()
            .css('overflow', 'visible');
          $('body').removeClass('mini-sidebar');
          $('.navbar-brand span').show();
          // $(".sidebartoggler i").addClass("ti-menu");
        } else {
          $('body').trigger('resize');
          $('.scroll-sidebar, .slimScrollDiv')
            .css('overflow-x', 'visible')
            .parent()
            .css('overflow', 'visible');
          $('body').addClass('mini-sidebar');
          $('.navbar-brand span').hide();
          // $(".sidebartoggler i").removeClass("ti-menu");
        }
      });
    });
  }

  togglePropDD() {
    this.propDropdown = !this.propDropdown;
  }

  closePropDD() {
    this.propDropdown = false;
  }

  logout() {
    // this.authenticationService.logout().then(resp => {
    //   if(resp)
    //     this.router.navigate(['login']);;
    // });

    this.authenticationService.simpleLogout();
    this.router.navigate(['/login']);
  }
}
