import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { AuthenticationService, HelperService, AppSettings } from '../../../../services/_services';

// Alert Plugin
import Swal from 'sweetalert2';

// Plugins
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";

/** Error when invalid control is dirty, touched, or submitted. */
export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if(typeof form.form['submitted'] === "undefined")
      form.form['submitted'] = false;

    // return form.control.controls.verifyPassword.value != form.control.controls.password.value;
    if(form.form['submitted'])
      return form.control.controls.verifyPassword.value != form.control.controls.password.value;
    else
      return (control.dirty && form.control.controls.verifyPassword.value != form.control.controls.password.value);
  }
}

export interface DialogData {
  Title  : string;
  animal : string;
  name   : string;
}

@Component({
  selector: 'app-agent-dialog',
  templateUrl: './agent-dialog.component.html',
  styleUrls: ['./agent-dialog.component.css']
})
export class AgentDialogComponent implements OnInit {

  public agent              : any = {
                                        is_agent  : 1,
                                        image     : '',
                                        agency    : { logo: '', owner_image: '', city_id: '', city: '', location_id: '', location: '' },
                                        offices   : [ { name: '', email: '' } ],
                                        staff     : [ { name: '', email: '', image: '' } ],
                                    };

  public AgentFormGroup     : FormGroup;
  public AgencyFormGroup    : FormGroup;
  public OfficeFormGroup    : FormGroup;
  public SatffFormGroup     : FormGroup;
  public errorMatcher       = new CrossFieldErrorMatcher();
  public corsHeaders        : any;
  public LoggedUser         : any;
  public isAddAgent         : any = true;
  public loading            : any = false;
  public verifyPass         : any = true;
  public staffImgIndex      : any = true;

  // City and Areas List
  public cities              : any = [{"id": 11, "name" : "Islamabad"}, {"id": 5, "name" : "Rawalpindi"}];
  public areas               : any = [];
  public SelectedArea        : any = [];
  public areasLoading        : any = false;
  public showAreasError      : any = false;

  @ViewChild('agentImg', {static: false}) agentImg: any;
  @ViewChild('myPond', {static: false}) myPond: any;

  agent_Pond_Options = {
    acceptedFileTypes: 'image/jpeg, image/png',
    // labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
    labelIdle: `Upload agent profile picture`,
    imagePreviewHeight: 170,
    imageCropAspectRatio: '1:1',
    imageResizeTargetWidth: 100,
    imageResizeTargetHeight: 100,
    stylePanelLayout: 'compact circle',
    styleLoadIndicatorPosition: 'center bottom',
    // styleButtonRemoveItemPosition: 'center bottom',

    styleProgressIndicatorPosition: 'center bottom',
    styleButtonProcessItemPosition: 'center bottom',

    server: {
        url: AppSettings.API_ENDPOINT,
        timeout: 7000,
        process: {
            url: './temp-images',
            method: 'POST',
            // headers: {
            //     'x-customheader': 'Hello World'
            // },
            withCredentials: false,
            onload: (response) => {
                let image = JSON.parse(response).temp_image;
                console.log("image: ",image);

                // Add image id to property image array
                this.agent.image = image.id;

                return image.id;
            },
            onerror: (response) => {
                console.log("response: ",response);
                return response.key;
            },
            ondata: (formData) => {
                formData.append('Hello', 'World');
                console.log("formData: ",formData);
                return formData;
            }
        },
        revert: (uniqueFileId, load, error) => {
            const url  = AppSettings.API_ENDPOINT + 'temp-images/' + uniqueFileId;
            this.helperService.httpDeleteRequests(url);

            // Remove image id from property image array
            this.agent.image = '';

            // Clear Image
            load();
        },
        restore: null,
        load: null,
        fetch: null
    }
  }

  agency_Pond_Options = {
    acceptedFileTypes: 'image/jpeg, image/png',
    // labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
    labelIdle: `Upload agency logo`,
    imagePreviewHeight: 170,
    imageCropAspectRatio: '1:1',
    imageResizeTargetWidth: 100,
    imageResizeTargetHeight: 100,
    stylePanelLayout: 'compact circle',
    styleLoadIndicatorPosition: 'center bottom',
    // styleButtonRemoveItemPosition: 'center bottom',

    styleProgressIndicatorPosition: 'center bottom',
    styleButtonProcessItemPosition: 'center bottom',

    server: {
        url: AppSettings.API_ENDPOINT,
        timeout: 7000,
        process: {
            url: './temp-images',
            method: 'POST',
            withCredentials: false,
            onload: (response) => {
                let image = JSON.parse(response).temp_image;
                console.log("image: ",image);

                // Add image id to this.agent.agency.logo
                this.agent.agency.logo = image.id;
                return image.id;
            },
            onerror: (response) => {
                console.log("response: ",response);
                return response.key;
            },
            ondata: (formData) => {
                formData.append('Hello', 'World');
                console.log("formData: ",formData);
                return formData;
            }
        },
        revert: (uniqueFileId, load, error) => {
            const url  = AppSettings.API_ENDPOINT + 'temp-images/' + uniqueFileId;
            this.helperService.httpDeleteRequests(url);

            // Remove image id from this.agent.agency.logo
            this.agent.agency.logo = '';

            // Clear Image
            load();
        },
        restore: null,
        load: null,
        fetch: null
    }
  }

  Owner_Pond_Options = {
    acceptedFileTypes: 'image/jpeg, image/png',
    // labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
    labelIdle: `Upload owner picture`,
    imagePreviewHeight: 170,
    imageCropAspectRatio: '1:1',
    imageResizeTargetWidth: 100,
    imageResizeTargetHeight: 100,
    stylePanelLayout: 'compact circle',
    styleLoadIndicatorPosition: 'center bottom',
    // styleButtonRemoveItemPosition: 'center bottom',

    styleProgressIndicatorPosition: 'center bottom',
    styleButtonProcessItemPosition: 'center bottom',

    server: {
        url: AppSettings.API_ENDPOINT,
        timeout: 7000,
        process: {
            url: './temp-images',
            method: 'POST',
            withCredentials: false,
            onload: (response) => {
                let image = JSON.parse(response).temp_image;
                console.log("image: ",image);

                // Add image id to this.agent.agency.logo
                this.agent.agency.owner_image = image.id;
                return image.id;
            },
            onerror: (response) => {
                console.log("response: ",response);
                return response.key;
            },
            ondata: (formData) => {
                formData.append('Hello', 'World');
                return formData;
            }
        },
        revert: (uniqueFileId, load, error) => {
            const url  = AppSettings.API_ENDPOINT + 'temp-images/' + uniqueFileId;
            this.helperService.httpDeleteRequests(url).then(resp => {
              // Remove image id from this.agent.agency.logo
              this.agent.agency.owner_image = '';
            });

            // Clear Image
            load();
        },
        restore: null,
        load: null,
        fetch: null
    }
  }

  satff_Pond_Options = {
    acceptedFileTypes: 'image/jpeg, image/png',
    // labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
    labelIdle: `Upload staff picture`,
    imagePreviewHeight: 170,
    imageCropAspectRatio: '1:1',
    imageResizeTargetWidth: 100,
    imageResizeTargetHeight: 100,
    stylePanelLayout: 'compact circle',
    styleLoadIndicatorPosition: 'center bottom',
    // styleButtonRemoveItemPosition: 'center bottom',

    styleProgressIndicatorPosition: 'center bottom',
    styleButtonProcessItemPosition: 'center bottom',

    server: {
        url: AppSettings.API_ENDPOINT,
        timeout: 7000,
        process: {
            url: './temp-images',
            method: 'POST',
            // headers: {
            //     'x-customheader': 'Hello World'
            // },
            withCredentials: false,
            onload: (response) => {
                let image = JSON.parse(response).temp_image;
                console.log("image: ",image);

                // Add image id to this.agent.agency.logo
                this.agent.staff[this.staffImgIndex].image = image.id;
                return image.id;
            },
            onerror: (response) => {
                console.log("response: ",response);
                return response.key;
            },
            ondata: (formData) => {
                formData.append('Hello', 'World');
                return formData;
            }
        },
        revert: (uniqueFileId, load, error) => {
            const url  = AppSettings.API_ENDPOINT + 'temp-images/' + uniqueFileId;
            this.helperService.httpDeleteRequests(url).then(resp => {
              // Remove image id from this.agent.agency.logo
              this.agent.staff[this.staffImgIndex].image = '';
            });

            // Clear Image
            load();
        },
        restore: null,
        load: null,
        fetch: null
    }
  }

  pondFiles = [
  ]

  staffPondFiles = [
    // 'assets/images/avatar.png'
  ]

  constructor(
    private dialog                        : MatDialog,
    public dialogRef                      : MatDialogRef<AgentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data  : DialogData,
    public formBuilder                    : FormBuilder,
    private helperService                 : HelperService,
    private cdr                           : ChangeDetectorRef
  ) {
      console.log("data: ",data);
  }

  ngOnInit() {
    // Set FormGroup
    this.AgentFormGroup = this.formBuilder.group({
      first_name           : ['', Validators.required],
      last_name            : ['', Validators.required],
      email                : ['', [Validators.required, Validators.email]],
      designation          : [''],
      // password             : ['', [Validators.required]],
      // verifyPassword       : [''],
      phone_number         : ['', [Validators.required]],
      mobile_number        : [''],
      message              : [''],
    }, {
      // validator: this.passwordValidator
    });

    this.AgencyFormGroup = this.formBuilder.group({
      agency_name          : ['', Validators.required],
      agency_email         : ['', [Validators.required, Validators.email]],
      agency_phone_number  : ['', Validators.required],
      agency_mobile_number : [''],
      agency_city          : ['', Validators.required],
      agency_location      : ['', Validators.required],
      agency_address       : [''],
      agency_description   : [''],

      agency_owner_name    : [''],
      agency_designation   : [''],
      agency_owner_message : [''],
    });

    this.OfficeFormGroup = this.formBuilder.group({
      office_name          : [''],
      office_email         : [''],
    });

    this.SatffFormGroup = this.formBuilder.group({
      satff_name          : [''],
      satff_email         : [''],
    });

  }

  selectCity() {
    // Reset Area Selects
    this.areas        = [];
    this.SelectedArea = [];

    if (this.agent.agency.city_id != "") {
        let index = this.cities.findIndex(x => x.id === this.agent.agency.city_id);
        this.agent.agency.city = this.cities[index].name;

        this.showAreasError = true;

        // Show Area Loader
        this.areasLoading = true;

        const url  = AppSettings.API_ENDPOINT + 'locations/get-all-locations?city_id=' + this.agent.agency.city_id;
        this.helperService.httpGetRequests(url).then(resp => {
          if(typeof resp.locations != "undefined") {
            // Set Areas
            this.areas        = resp.locations;

            // Hide Area Loader
            this.areasLoading = false;
          }
        }).catch(error => {
            console.error("error: ",error);
        });
    }
  }

  selectLocation() {
    if(this.SelectedArea != null) {
      this.showAreasError = false;

      this.agent.agency.location_id = this.SelectedArea.id;
      this.agent.agency.location    = this.SelectedArea.name;
    }
    else
      this.showAreasError = true;
  }


  pondHandleInit() {
    // console.log('FilePond has initialised agentImg', this.agentImg);
    // console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  pondHandleAddFile_Staff(event: any, index) {
    this.staffImgIndex = index;
  }

  pondHandleRemoveFile_Staff(event: any, index) {
    this.staffImgIndex = index;
  }

  pondHandleRemoveFile(event: any) {
  }

  // convenience getter for easy access to form fields
  get agent_f() { return this.AgentFormGroup.controls; }
  get agency_f() { return this.AgencyFormGroup.controls; }
  get office_f() { return this.OfficeFormGroup.controls; }
  get satff_f() { return this.SatffFormGroup.controls; }

  close(): void {
    this.dialogRef.close({});
  }

  setFormSubmitted() {
    this.AgentFormGroup['submitted'] = true;
  }

  AddRemove_Office(action) {
    if(action == "add")
      this.agent.offices.push({ name: '', email: ''});
    else
      this.agent.offices.pop();
  }

  AddRemove_Staff(action) {
    if(action == "add")
      this.agent.staff.push({ name: '', email: '', image: '' });
    else
      this.agent.staff.pop();
  }

  save() {
    // this.AgentFormGroup['submitted'] = true;
    //
    // // mark all fields as touched
    // this.markFormGroupTouched(this.AgentFormGroup);
    for (let i = 0; i < this.agent.offices.length; i++) {
        if (this.agent.offices[i].name == '' && this.agent.offices[i].email == '') {
            this.agent.offices.splice(i, 1);
        }
    }

    for (let i = 0; i < this.agent.staff.length; i++) {
        if (this.agent.staff[i].name == '' && this.agent.staff[i].email == '') {
            this.agent.staff.splice(i, 1);
        }
    }

    console.log("this.agent: ",this.agent);

    if (this.AgentFormGroup.invalid) {
        return;
    }


    // Show Loader
    this.loading = true;

    const url = AppSettings.API_ENDPOINT + 'admin/agent';
    this.helperService.httpPostRequests(url, this.agent).then(resp => {

        // Set Agent Deatils
        let agent              = resp.user;
        agent.user_name        = agent.first_name + ' ' + agent.last_name;
        agent.agency_name      = resp.agency.name;
        agent.total_properties = 0;

        // Close the modal
        this.dialogRef.close({ success : true, agent: agent });

        Swal.fire('Success', "Agent added successfully", 'success');

        // Hide Loader
        this.loading = false;
    }).catch(error => {
        console.log("error: ",error);

        if(typeof error.error.errors.email != "undefined") {
          Swal.fire('Error', error.error.errors.email[0], 'error');
        }

        // Hide Loader
        this.loading = false;
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      // if (control.controls) {
      //     control.controls.forEach(c => this.markFormGroupTouched(c));
      // }
    });
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('verifyPassword').value;
    return condition ? { passwordsDoNotMatch: true} : null;
  }

}
